import Column_DefaultColumnComponent from '@wix/thunderbolt-elements/src/components/Column/viewer/skinComps/DefaultCoulmn/DefaultColumn.skin';


const Column_DefaultColumn = {
  component: Column_DefaultColumnComponent
};


export const components = {
  ['Column_DefaultColumn']: Column_DefaultColumn
};

